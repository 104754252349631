// GOOGLE FONTS
@import url('https://fonts.googleapis.com/css2?family=Arvo&family=Bungee&family=Josefin+Sans&family=Mukta:wght@200&family=Spartan:wght@200;570;600&family=Yanone+Kaffeesatz:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Averia+Serif+Libre&family=Nabla&display=swap');
// COLORS
$light-base: #BE97C6;
$dark-base: #4B5267;
$link-base: #8661C1;
$white: #FFFFFF;
// SPACING + SIZE
$tiny-size: 5px;
$small-size: 10px;
$med-size: 20px;
$large-size: 30px;
$xlarge-size: 35px;
$huge-size: 55px;
$massive-size: 120px;
$xxlarge-size: 140px;
// FONT SIZE
$tiny-font: 10px;
$small-font: 14px;
$med-font: 18px;
$large-font: 24px;
$huge-font: 60px;
//////////////////

.about {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-top: $huge-size;
  margin-bottom: $large-size;
  margin-left: $huge-size;
  padding-top: $huge-size;
  width: 800px;

  .aboutMe {
    display: flex;
    flex-direction: column;
    font-size: $large-font;
    text-align: center;
    flex: 1;
  }

  .aboutMeContainer {
    display: flex;
  }
}

.aboutMeText {
  color: $dark-base;
  font-size: 22px;
  margin: $small-font;
  text-align: left;
}

.absolute {
  position: absolute;
}

.active {
  display: flex;
}

.bigTitle {
  color: $light-base;
  font-family: 'Spartan', sans-serif;
  font-size: $med-size;
  font-weight: bold;
  text-align: center;
}

.burgerClosed {
  cursor: pointer;
  background: transparent;
  border: none;
  display: none;
  flex-direction: column;
  height: 2rem;
  justify-content: space-around;
  margin-right: 5px;
  padding: 0;
  position: fixed;
  right: 1%;
  top: 1%;
  width: 2rem;
  z-index: 9999;

  &:focus {
    outline: none;
  }

  div {
    background: $light-base;
    border-radius: $small-size;
    height: 0.25rem;
    position: relative;
    margin-right: 10px; 
    transition: all 0.3s linear;
    transform-origin: 1px;
    width: 2rem;

    :first-child {
      transform: rotate(45deg);
    }

    :nth-child(2) {
      opacity: '0';
      transform: translateX($med-size);
    }

    :nth-child(3) {
      transform: rotate(-45deg);
    }
  }
}

.burgerOpen {
  cursor: pointer;
  background: transparent;
  border: none;
  display: none;
  flex-direction: column;
  height: 2rem;
  justify-content: space-around;
  position: absolute;
  right: 0;
  top: 10%;
  width: 2rem;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    background: $dark-base;
    border-radius: $small-size;
    height: 0.22rem;
    position: relative;
    transition: all 0.3s linear;
    transform-origin: 1px;
    width: 2rem;
  }

  :first-child {
    transform: rotate(45deg);
  }

  :nth-child(2) {
    opacity: 0;
    transform: translateX($med-size);
  }

  :nth-child(3) {
    transform: rotate(-45deg);
  }
}

.center {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.closed {
  cursor: pointer;
  background: transparent;
  border: none;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 2rem;
  padding: 0;
  top: 5%;
  right: 2rem;
  width: 2rem;
  z-index: 10;
  
  &:focus {
    outline: none;
  }
  
  div {
    background: $white;
    border-radius: $small-size;
    height: 0.25rem;
    position: relative;
    transition: all 0.3s linear;
    transform-origin: 1px;
    width: 2rem;
    
    :first-child {
      transform: rotate(0);
    }
    
    :nth-child(2) {
      opacity: '1';
      transform: translateX(0);
    }
    
    :nth-child(3) {
      transform: rotate(0);
    }
  }
}

.contact {
  margin-top: $huge-size;
  margin-bottom: $massive-size;
}

.contactContent {
  display: flex;
  flex-direction: column;
  padding-top: $huge-size;
  transition: opacity 0.5s;
  width: 800px;
  opacity: 1;
}

.content {
  background-color: $white;
  font-family: 'Spartan', sans-serif;
}

@font-face {
  font-family: 'Magilio';
  src:  local('Magilio'), url(./fonts/MagilioRegular-8Mxvg.otf) format('opentype');
}

@font-face {
  font-family: 'Gyahegi';
  src:  local('Gyahegi'), url(./fonts/GyahegiPersonalUse-w1P6z.ttf) format('opentype');
}

.contactInfo {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 0px;
  position: fixed;
  top: 50px;
}

.company {
  color: $white;
  font-weight: bolder;
}

.centerTitle {
  color: $light-base;
  font-family: 'Spartan', sans-serif;
  font-weight: bold;
  font-size: $large-size;
  text-align: center;
  width: 100%;
}

.centerTitleDark {
  color: $dark-base;
  font-family: 'Spartan', sans-serif;
  font-weight: bold;
  font-size: $huge-size;
  text-align: center;
  width: 100%;
}

.divider {
  background-color: $white;
  margin-bottom: $huge-size;
  width: 100%;
}

.dividerTitle {
  margin-top: $huge-size;
  color: $light-base;
  font-family: 'Spartan', sans-serif;
  font-weight: bold;
  padding: $small-size;
}

.dropdownMenu {
  display: flex;
  flex-direction: column;
  padding: $small-size;
  z-index: 9999;
}

.dropdownItem {
  color: $white;
  padding: $small-size;
  position: absolute;
  text-decoration: none;
}

.fade-in-section-about {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section-about.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(9vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform .4s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.experience {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  margin-left: $huge-size;
  margin-bottom: $huge-size;
  padding-top: $massive-size;
  width: 800px;
  opacity: 1;
}

.experienceContent {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.experienceText {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.footerText {
  color: $dark-base;
  display: flex;
  padding: $large-font;
  flex-direction: row;
  justify-content: center;
}

.hamburgerMenu {
  display: none;
}

.headerBackground {
  background-color: $white;
  height: 50px;
  opacity: 0.8;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1;
}

.headerBar {
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: space-between;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 9999;
}

.headerContent {
  align-items: center;
  color: $white;
  display: flex;
  flex-direction: column;
  font-family: 'Spartan', sans-serif;
  font-size: $small-size;
  justify-content: space-between;
  margin: 0px;
  text-align: center;
}

.headerLinks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: $small-size;
}

.headerLink {
  color: $light-base;
  cursor: pointer;
  font-weight: bolder;
  font-size: $med-font;
  margin: $large-size;
  
  &:hover {
    color: $dark-base;
    text-decoration: none;
  }
}

.inOrder {
  animation: 2s in-order;
}

@keyframes in-order {
  0% {
    opacity: 0;
    transform: translateX(30%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateX(0%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.hidden {
  display: none;
}

.displayTitle {
  color: $dark-base;
  font-family: "Gyahegi";
  font-palette: --myPalette;
  font-size: $massive-size;
  font-weight: bold;
  margin-bottom: $huge-size;
  text-align: left;
  max-width: 50%;
}

@font-palette-values --myPalette {
  font-family: "Nabla";
  base-palette: 4;
  override-colors: 6 $link-base;
}

.hugeTitle {
  color: $dark-base;
  font-family: 'Spartan', sans-serif;
  font-size: 25px;
  font-weight: bold;
  text-align: left;
}

.imageContainer {
  width: 450px;
}

.image {
  border: 1px solid $white;
  border-radius: 2px;
  width: 100%;
  height: auto;
  opacity: .5;
}

.icon {
  margin: $med-size;
  max-width: $large-size;
  
  &:hover path {
    fill: $dark-base;
    text-decoration: none;
  }
}

.darkIcon {
  margin: $med-size;
  max-width: $large-size;
  
  &:hover path {
    fill: $dark-base;
    text-decoration: none;
  }
}

.leftTitle {
  color: $dark-base;
  font-family: 'Magilio', sans-serif;
  font-weight: bold;
  font-size: $huge-font;
  justify-content: flex-start;
  text-align: left;
  margin-bottom: $med-size;
  margin-left: $small-font;
}

.link {
  color: $light-base;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  
  &:hover {
    color: $dark-base;
    text-decoration: none;
  }
}

.lineUp {
  animation: 2s anim-lineUp ease-out;
}

@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.mainSection {
  align-items: center;
  color: $dark-base;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  text-align: center;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.menu {
  opacity: 1;
}

.open {
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 2rem;
  padding: 0;
  position: absolute;
  right: 2rem;
  top: 5%;
  width: 2rem;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    background: $dark-base;
    border-radius: $small-size;
    height: 0.25rem;
    position: relative;
    transition: all 0.3s linear;
    transform-origin: 1px;
    width: 2rem;

    :first-child {
      transform: rotate(45deg);
    }

    :nth-child(2) {
      opacity: 0;
      transform: translateX($med-size);
    }

    :nth-child(3) {
      transform: rotate(-45deg);
    }
  }
}

.position {
  color: $white;
  font-size: $large-font;
  font-weight: bold;
}

.portrait {
  border-radius: .8%;
  margin: $small-size;
  max-height: 480px;
  max-width: 480px;
}

.meText {
  color: $dark-base;
  flex-direction: column;
  font-size: $large-font;
  font-weight: lighter;
  margin: $small-size;
  justify-content: center;
  align-items: center;
}

.menuOpen {
  background: $white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 455vh;
  padding: 2rem;
  position: absolute;
  right: -$med-size;
  text-align: left;
  top: 0;
  transition: transform 0.3s ease-in-out;
  
  a {
    color: $dark-base;
    cursor: pointer;
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 0.3rem;
    padding: 2rem 0;
    text-decoration: none;
    text-transform: uppercase;
    transition: color 0.3s linear;

    :first-child {
      margin-top: $med-size;
    }

    &:hover {
      color: $dark-base;
      text-decoration: none;
    }
  }
}

.menuClosed {
  background: $white;
  display: none;
  flex-direction: column;
  height: 455vh;
  justify-content: flex-start;
  opacity: 0;
  padding: 2rem;
  position: absolute;
  right: 0;
  text-align: left;
  top: 0;
  transition: transform 0.3s ease-in-out;
  
  a {
    color: $dark-base;
    cursor: pointer;
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 0.3rem;
    padding: 2rem 0;
    text-decoration: none;
    text-transform: uppercase;
    transition: color 0.3s linear;

    &:hover {
      color: $dark-base;
      text-decoration: none;
    }
  }
}

.notVisible {
  opacity: 0;
}

.resumeButton {
  border-radius: $small-size;
  background-color: $white;
  color: $light-base;
  font-family: 'Spartan', cursive;
  font-size: $med-font;
  font-weight: bolder;
  padding-top: $med-size;
  padding-bottom: $med-size;
  padding-left: $small-size;
  padding-right: $small-size;
  text-decoration: none;
  max-width: 300px;
}

.resumeButton:hover {
  background-color: $white;
  color: $dark-base;
  text-decoration: none;
}

.resumeButtonContainer {
  margin: $med-size;
  align-items: flex-start;
}

.ctaText {
  border-radius: $small-size;
  background-color: $white;
  color: $light-base;
  font-family: 'Spartan', cursive;
  font-size: $med-font;
  font-weight: bolder;
  padding-top: $med-size;
  padding-bottom: $med-size;
  text-decoration: none;
  max-width: 300px;
}

.ctaText:hover {
  background-color: $white;
  color: $dark-base;
  text-decoration: none;
}

.skillColumn {
  display: flex;
  color: $white;
  flex-direction: column;
  font-size: $small-font;
  margin: $med-size;
  text-align: center;
}

.skillColumnContainer {
  display: flex;
  text-align: center;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: $small-size;
}

.skillTitle {
  color: $dark-base;
  font-size: 12px;
  font-weight: bolder;
  max-width: 300px;
}

.welcomeTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 600px;
}

.welcomeText {
  display: flex;
  flex-direction: column;
  margin-left: $large-size;
  margin-top: 100px;
  margin-bottom: $huge-size;
}

@media (max-width: 650px) {
  .about {
    flex-direction: column;
    margin-bottom: $small-size;
    margin-left: 0px;
    padding-top: $large-size;
    padding-bottom: $med-size;
  }

  .aboutMeText {
    font-size: 22px;
    max-width: 325px;
    text-align: center;
  }
  
  .aboutMeContainer {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  
  .burgerClosed {
    display: flex;
    margin-right: 5px;
  }
  
  .burgerOpen {
    display: flex;
  }

  .columnContainer {
    flex-direction: column;
  }

  .contact {
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .contactContent {
    padding-top: $massive-size;
    margin-top: $huge-size;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .content {
    overflow: hidden;
    width: 100%;
  }

  .socialIcons {
    margin-bottom: $huge-size;
  }

  .contactInfo {
    display: none;
  }

  .contactMeText {
    font-size: $med-font;
    font-weight: light;
    max-width: 300px;
  }

  .centerTitleDark {
    font-size: $large-size;
  }

  .displayTitle {
    font-size: 60px;
  }

  .experience {
    align-items: center;
    margin-left: 0px;
    margin-bottom: 0px;
    margin-top: $huge-size;
  }

  .footerText { 
    text-align: center;
    font-size: $small-font;
  }

  .headerContent {
    align-items: flex-end;
  }

  .headerLinks {
    display: none;
  }

  .hamburgerMenu {
    background-color: $dark-base;
    display: flex;
    justify-content: flex-end;
  }

  .header {
    flex-direction: column;
  }

  .hide {
    margin-top: 0px;
  }

  .hugeTitle {
    font-size: $small-font;
  }

  .leftTitle {
    align-items: center;
    font-size: 45px;
    justify-content: center;
    margin-bottom: 0px;
    text-align: center;
  }

  .image {
    display: none;
    justify-content: center;
    align-items: center;
  }

  .mainSection {
    width: 100%;
    margin-top: 20px;
  }

  .meText {
    font-size: $large-font;
    max-width: 300px;
  }

  .resumeButton {
    font-size: $med-font;
  }

  .resumeButtonContainer {
    margin: $med-size;
    max-width: 100%;
    text-align: center;
  }

  .skillColumn {
    font-size: $tiny-font;
    margin: $small-size;
  }

  .skillColumnContainer {
    flex-direction: row;
  }

  .skillTitle {
    max-width: 100%;
    font-size: $tiny-font;
  }

  .visible {
    opacity: 1;
    transition: opacity 0.6s ease-out;
    transform: translateY(20vh);
    visibility: visible;
    will-change: opacity, visibility;
  }

  .welcomeText {
    margin-left: $small-size;
  }

  .welcomeTextContainer {
    height: 250px;
  }
}
